html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    background-color: #fff;
    color: $font;
    background: #f7f7f7;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    transition: all .3s;
}

p {
    font-size: 16px;
    color: $font;
    font-weight: 400;
    margin: 7px 0;
    line-height: 1.4;
}

h2 {
    font-family: 'Akrobat', sans-serif;
    font-size: 41px;
    font-style: italic;
}

h5 {
    color: lightgray;
}

@media only screen and (max-width : 992px) {
    h2 {
        font-size: 26px
    }
}

hr {
    margin: 0;
    padding: 0;
    opacity: .5;
    background-color: #ececec;
}

article {
    p {
        line-height: 1.7;
        margin: 10px 0;
    }

    .text-main {
        padding: 20px 20px 20px 40px;
        background: #f6f6f6;
        border-left: 2px solid $orange;
        margin: 7px 0;
    }
}

@media only screen and (max-width : 576px) {
    .text-main {
        padding: 15px 15px 15px 25px;
        background: #f6f6f6;
        border-left: 2px solid $orange;
        margin: 7px 0;
    }

}
