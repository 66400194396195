@charset "UTF-8";
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

@font-face {
  font-family: "Akrobat";
  src: url("../fonts/../fonts/Akrobat-ExtraBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto Bold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
/**
 * Сброс стилей
 **/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

input {
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  color: #282828;
  background: #f7f7f7;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

p {
  font-size: 16px;
  color: #282828;
  font-weight: 400;
  margin: 7px 0;
  line-height: 1.4;
}

h2 {
  font-family: "Akrobat", sans-serif;
  font-size: 41px;
  font-style: italic;
}

h5 {
  color: lightgray;
}

@media only screen and (max-width: 992px) {
  h2 {
    font-size: 26px;
  }
}
hr {
  margin: 0;
  padding: 0;
  opacity: 0.5;
  background-color: #ececec;
}

article p {
  line-height: 1.7;
  margin: 10px 0;
}
article .text-main {
  padding: 20px 20px 20px 40px;
  background: #f6f6f6;
  border-left: 2px solid #f15a25;
  margin: 7px 0;
}

@media only screen and (max-width: 576px) {
  .text-main {
    padding: 15px 15px 15px 25px;
    background: #f6f6f6;
    border-left: 2px solid #f15a25;
    margin: 7px 0;
  }
}